.pet-list{
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	width: 80%;
}

.pet-image{
	display: block; 
	margin: 0 auto; 
	height: 8rem;
	border-radius: 5px;
	max-width: 100%;
}