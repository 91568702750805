body {
    margin: 0;
    padding: 0;
}
header {
	display: flex;
	justify-content: space-between;
	padding: 1rem;
    text-align: center;
}
footer {
	padding: 20px;
    text-align: center;
	min-height: 200px;
}
.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.logo-box {
	display: flex;
	align-items: center;
	height: 2rem;
	width: fit-content;
}
.logo {
	height: 2rem;
	width: 2rem;
}
nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: right;
}
nav ul li {
    margin-left: 1rem;
}
a {
	color: #eeeeee;
    text-decoration: none;
}
.text-white{
	color: #ffffff;
}
.text-gray{
	color: #eeeeee;
}
.text-red{
	color: #ff5722;
}
.text-blue{
	color: #00adb5;
}
.text-black{
	color: #303841;
}
.bg-white{
	background-color: #ffffff;
}
.bg-gray{
	background-color: #eeeeee;
}
.bg-red{
	background-color: #ff5722;
}
.bg-blue{
	background-color: #00adb5;
}
.bg-black{
	background-color: #303841;
}
.title-text-1{
	font-weight: 1000;
	font-size: 2rem;
	text-align: center;
}
.title-text-2{
	font-weight: 700;
	font-size: 1.5rem;
	text-align: center;
}
.inner-text-1{
	font-size: 1rem;
	text-align: center;
}
.inner-text-2{
	font-size: 0.6rem;
	text-align: center;
}
.btn{
	background-color: #ff5722;
	color: #eeeeee;
	border: none;
	border-radius: 5px;
    padding: 10px;
	cursor: pointer;
	transition: background-color 0.2s, transform 0.2s;
}
.btn:hover{
	background-color: #e64a19;
	transform: scale(1.05);
}
.btn:active{
	background-color: #e64a19;
	transform: scale(0.95);
}

.flex-col{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.padding-10{
	padding-top: 10px;
}

/*点击按钮*/
.clickable-box input,select,textarea{
	padding: 10px;
	font-size: 1rem;
	border: 2px solid #ff5722;
	border-radius: 5px;
	margin-right: 10px;
	transition: border-color 0.3s, transform 0.2s;
}
.clickable-box input:hover,select:hover,textarea:hover{
	border-color: #e64a19;
	transform: scale(1.02);
}
.clickable-box input:active,select:active,textarea:active{
	border-color: #e64a19;
	transform: scale(0.99);
}

/*卡片*/
.card {
	position:relative;
	width: 90%;
	max-width: 200px;
	height: 200px;
	margin: 10px;
	background-color: #ffffff;
	border: 3px solid #00adb5;
	border-radius: 5px;
	padding: 10px;
	transition: 0.3s;
}
.card label{
	width: inherit;
	height: inherit;
}
.card .card-front, .card .card-back {
	position: absolute;
	width: inherit;
	height: inherit;
	backface-visibility: hidden;
	word-wrap: break-word;
	overflow-wrap: break-word;
	transition: transform 0.3s;
}
.card input{
	display: none;
}
.card .card-back {
	transform: rotateY(180deg);
}
.card input:checked ~ label .card-back{
	transform: rotateY(0deg);
}
.card input:checked ~ label .card-front{
	transform: rotateY(180deg);
}
.card:has(input:checked) {
	border: 3px solid #ff5722;
}

.section-box{
	padding-top: 20px;
	padding-bottom: 20px;
}

.section-box.flex{
	display: flex;
	flex-direction: column;
	align-items: center;
}

table.table-theme{
    border: 3px solid #00adb5;
    border-radius: 5px;
    background-color: #ffffff;
    border-collapse: separate;
    border-spacing: 0;
}
table.table-theme thead th {
    padding: 10px;
    background-color: #00adb5;
    color: #ffffff;
    text-align: left;
}
table.table-theme tbody td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}
table.table-theme tbody tr:last-child td {
    border-bottom: none;
}

input.input-text-box{
	background-color: #ffffff;
	border: 3px solid #00adb5;
	border-radius: 5px;
	margin-left: 5px;
	margin-right: 5px;
}

.info-box {
    margin: 15px 0;
    padding: 15px;
    background-color: #ffffff;
    border: 2px solid #00adb5;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;
    text-align: left;
	transition: background-color 0.3s;
}
.info-box h3 {
    margin-top: 0;
}
.info-box p {
    margin: 5px 0 0;
}
.info-box:hover {
    background-color: #eeeeee;
}