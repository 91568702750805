.btn{
	display: block;
	margin: 0 auto;
}

.loading-placeholder {
	width: 40px;
	height: 40px;
	border: 4px solid #ccc;
	border-top: 4px solid #00adb5; /* 设置旋转圈的颜色 */
	border-radius: 50%;
	animation: spin 1s linear infinite;
	margin: auto; /* 居中 */
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}