.pet-info-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.pet-details {
	display: flex;
	gap: 20px;
}

.pet-image-info {
	width: 200px;
	height: auto;
	border-radius: 8px;
}

.pet-info p {
	margin: 5px 0;
	font-size: 16px;
}